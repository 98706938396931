import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { ArticlesNodesProps } from './models';

import './articlesNodes.scss';

const ArticlesNodes: FC<ArticlesNodesProps> = ({ article: { url, basicInfo } }) => {
  const {
    banner: { image },
    summary,
    title,
  } = basicInfo[0];

  return (
    <>
      <article className="article">
        <Link className="article__link link" to={url}>
          <Image imageData={image} alt={url} />
        </Link>
        <Link className="article__link--title link" to={url}>
          <div className="container__title">
            <h1 className="article__title">{title}</h1>
          </div>
        </Link>
        <p className="article__description">{summary}</p>
      </article>
    </>
  );
};

export default ArticlesNodes;
