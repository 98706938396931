import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ArticleListingComponent from 'layout/ArticleListingComponent';

import { ArticlePageProps } from './models';

const ArticlesListingPage: FC<ArticlePageProps> = ({ data: { articlesListingPage } }) => (
  <ArticleListingComponent node={articlesListingPage} />
);
export const query = graphql`
  query ($url: String) {
    articlesListingPage(url: { eq: $url }) {
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      url
      articles {
        url
        name
        basicInfo {
          title
          summary
          banner {
            image {
              childImageSharp {
                gatsbyImageData(width: 400, height: 230)
              }
            }
          }
        }
      }
    }
  }
`;

export default ArticlesListingPage;
