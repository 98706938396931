import React, { FC } from 'react';

import { Layout } from 'layout';
import ArticlesNodes from 'layout/ArticlesNodes';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { ArticleListingComponentProps } from './models';

const ArticleListingComponent: FC<ArticleListingComponentProps> = ({
  node: { articles, title, seoMetaTitle, seoMetaDescription, seoMetaKeywords },
}) => {
  const renderArticle = articles.map((article) => {
    const { name } = article;

    return (
      <div className="gid__column--4 col-6 col-md-4" key={name}>
        <ArticlesNodes article={article} />
      </div>
    );
  });

  return (
    <Layout>
      {seoMetaTitle !== undefined &&
        seoMetaDescription !== undefined &&
        seoMetaKeywords !== undefined && (
          <Seo
            {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
          />
        )}
      {seoMetaTitle !== undefined &&
        seoMetaDescription !== undefined &&
        seoMetaKeywords !== undefined && (
          <PageSchema
            type="WebPage"
            name={seoMetaTitle}
            data={{
              metaTitle: seoMetaTitle,
              metaDescription: seoMetaDescription,
              metaKeywords: seoMetaKeywords,
            }}
          />
        )}
      <div className="container articles">
        <div className="grid__row row">
          <div className="grid__column--12 col-12">
            <div className="container__title">
              <h1 className="title">{title}</h1>
            </div>
          </div>
          {renderArticle}
        </div>
      </div>
    </Layout>
  );
};

export default ArticleListingComponent;
